<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.companyInfo')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- Company Id -->
            <v-col class="py-0" cols="12" lg="2" md="2" sm="2">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.customerID')"
                :rules="generalRule"
                v-model="myObj.code"
              />
            </v-col>

            <!-- Company Name -->
            <v-col class="py-0" cols="12" lg="5" md="5" sm="5">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.companyName')"
                :rules="generalRule"
                v-model="myObj.company"
              />
            </v-col>

            <!-- Website -->
            <v-col class="py-0" cols="12" lg="5" md="5" sm="5">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.website')"
                :rules="generalRule"
                v-model="myObj.website"
              />
            </v-col>

            <!-- Tax No -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.taxNo')"
                :rules="generalRule"
                v-model="myObj.tax_no"
              />
            </v-col>

            <!-- Business Registration No -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.registrationNo')"
                :rules="generalRule"
                v-model="myObj.registration_no"
              />
            </v-col>

            <!-- Expire Date -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.expireDate')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="myObj.expire_date"
                    :rules="generalRule"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.expire_date"
                  @input="menu = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- contact Person -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.contactPerson')"
                :rules="generalRule"
                v-model="myObj.contact"
              />
            </v-col>

            <!-- Address -->
            <v-col class="py-0" cols="12" lg="8" md="8" sm="8">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.addr')"
                :rules="generalRule"
                v-model="myObj.address"
              />
            </v-col>

            <!-- Phone -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.phone')"
                :rules="generalRule"
                v-model="myObj.tel"
              />
            </v-col>

            <!-- Phone 2 -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.phone2')"
                :rules="generalRule"
                v-model="myObj.phone2"
              />
            </v-col>

            <!-- Fax -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.fax')"
                :rules="generalRule"
                v-model="myObj.fax"
              />
            </v-col>

            <!-- MailBox -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.email')"
                :rules="generalRule"
                v-model="myObj.email"
              />
            </v-col>

            <!-- Remark -->
            <v-col class="py-0" cols="12">
              <v-textarea
                outlined
                :label="$vuetify.lang.t('$vuetify.note')"
                v-model="myObj.note"
              />
            </v-col>

            <!-- Upload File -->
            <v-col class="py-0" cols="12">
              <v-file-input
                v-model="files"
                placeholder="Upload your documents"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept="image/jpeg, image/png, application/pdf"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>

            <!-- List Files -->
            <v-col cols="12" class="pt-0" v-if="myObj.attach.length > 0">
              <span class="display-1 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                elevation="4"
                class="py-4"
                :height="myObj.attach.length < 1 ? '200px' : ''"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in myObj.attach"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    :href="dataUrl + item.md5"
                    target="_blank"
                  >
                    {{ item.filename }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    @click="removeAttach(item)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import serverConfig from "@/utils/serverConfig";
import profilePicUpload from "@/api/company";
import { draggable } from "@/api/customFunction";

export default {
  name: "InfoCRUD",
  props: { dialog: Boolean, myObj: Object },
  components: {
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  data() {
    return {
      imgUrl: serverConfig.img_url,
      date: new Date().toString().substr(0, 10),
      menu: false,
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
      files: undefined,
      dataUrl: serverConfig.img_url,
    };
  },
  methods: {
    drag() {
      draggable();
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("handleCancel");
    },
    reset() {
      console.log("reset");
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj, this.files)
        : console.log("false");

      this.files = undefined;
    },
    removeAttach(item) {
      this.myObj.attach.splice(this.myObj.attach.indexOf(item), 1);
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped>
.tile {
  border: 1px solid black;
}
.tile:hover {
  background: lightgray;
}
.tile:active {
  background: lightslategray;
}
</style>
